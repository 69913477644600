import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExclamation,
  faMessage,
  faSpinner
} from '@fortawesome/pro-solid-svg-icons';

import FileViewer from './file_viewer';
import VaultAccess from './vault_access';
import EmptyState from './empty_state';
import RequestMoreInfo from './request_more_info';

import { fetchNavigation } from '../actions';
import { setShowLogin, setShowInfoRequest } from '../actions/ui';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { formatContainerClasses } from '../helpers/card_component_container_class';
import ChangeDocAccessPortal from './vault_steps/preview_portals/change_doc_access_portal';
import { PROFILE_OPTIONS } from '../actions/preview';
import parseNavigationVariables from '../helpers/parse_navigation_variables';
import LostAccess from './lost_access';
import DocumentNavigationSidebar from './document_navigation_sidebar';

const CONTENT_TO_SHOW = {
  REQUEST_MORE_INFO: 'request_more_info',
  VAULT_ACCESS: 'vault_access',
  FILE: 'file',
  EMPTY_STATE: 'empty_state',
  LOST_ACCESS: 'lost_access',
};

export function DocumentNavigation({
  currentFolder,
  currentFolderLoaded,
  fetchNavigation,
  file,
  fileNavigationContainerSelector,
  hasVaultGates,
  isEuRequest,
  profile,
  lockedFolder,
  parentFolder,
  readonly,
  recaptchaKey,
  rootFolder,
  setShowLogin,
  showRequestMoreInfo,
  setShowInfoRequest,
  showLogin
}) {
  useEffect(() => {
    if (!rootFolder) fetchNavigation();
  }, [rootFolder]);

  if (!rootFolder) {
    return (
      <div className="text-center my-5 text-muted">
        <FontAwesomeIcon icon={faSpinner} size="2x" spin/>
      </div>
    );
  }

  const vaultAccessComplete = (result) => {
    setShowLogin(false);
    fetchNavigation(result?.onlyGetUnlockedFile || false);
  };

  const isDocsPresent = (folder) => {
    return folder.children.some((child) => {
      if (child.folder) {
        return isDocsPresent(child);
      }

      return true;
    });
  };

  if (!isDocsPresent(rootFolder)) {
    let ChildComponent = (
      <React.Fragment>
        <div className="my-auto">
          <EmptyState
            cardClasses="connect-card"
            header="There are no documents available here."
            icon={faFileExclamation}
          />
        </div>
        <div className="mt-auto"/>
      </React.Fragment>
    );


    if (showLogin) {
      ChildComponent = <VaultAccess loginOnly={showLogin} noDocs onRefreshNavigation={vaultAccessComplete}/>;
    } else if (hasVaultGates) {
      ChildComponent = <VaultAccess loginOnly={false} noDocs onRefreshNavigation={vaultAccessComplete}/>;
    }

    return (
      <div className={formatContainerClasses}>
        {ChildComponent}
      </div>
    );
  }

  let contentToShow = CONTENT_TO_SHOW.EMPTY_STATE;
  if (showRequestMoreInfo) {
    contentToShow = CONTENT_TO_SHOW.REQUEST_MORE_INFO;
  } else if (showLogin || (file && file.locked)) {
    contentToShow = CONTENT_TO_SHOW.VAULT_ACCESS;
  } else if ((file && file.lostAccess) || lockedFolder) {
    contentToShow = CONTENT_TO_SHOW.LOST_ACCESS;
  } else if (file) {
    contentToShow = CONTENT_TO_SHOW.FILE;
  }

  function handlePreviewAccessChange(_, __, updatedProfile) {
    fetchNavigation(false, updatedProfile);
  }

  const showBottomPortal = contentToShow !== CONTENT_TO_SHOW.VAULT_ACCESS && readonly &&
    !!profile[PROFILE_OPTIONS.ACCESS_LEVEL] && !hasVaultGates;

  return (
    <React.Fragment>
      {!isEuRequest && ReactDOM.createPortal((
        <Button disabled={showRequestMoreInfo} name="request-more-info-button" onClick={() => setShowInfoRequest(true)}>
          <FontAwesomeIcon className="me-2" icon={faMessage}/>
          Contact Broker
        </Button>
      ), document.querySelector('#js-request-more-info-button'))}

      <DocumentNavigationSidebar
        currentFolder={currentFolder}
        currentFolderLoaded={currentFolderLoaded}
        file={file}
        fileNavigationContainerSelector={fileNavigationContainerSelector}
        isEuRequest={isEuRequest}
        parentFolder={parentFolder}
      />
      {contentToShow === CONTENT_TO_SHOW.REQUEST_MORE_INFO && (
        <div className={formatContainerClasses} name="request-more-info-container">
          <RequestMoreInfo recaptchaKey={recaptchaKey}/>
        </div>
      )}
      {contentToShow === CONTENT_TO_SHOW.VAULT_ACCESS && (
        <div className={formatContainerClasses}>
          <VaultAccess loginOnly={showLogin} onRefreshNavigation={vaultAccessComplete}/>
        </div>
      )}
      {contentToShow === CONTENT_TO_SHOW.LOST_ACCESS && (
        <LostAccess entry={lockedFolder || file}/>
      )}
      {contentToShow === CONTENT_TO_SHOW.FILE && (
        <div name="file-viewer-container">
          <FileViewer
            file={file}
            higherFloatingButton={showBottomPortal}
            key={file.id}
            previewProfile={profile}
            readonly={readonly}
          />
        </div>
      )}
      {contentToShow === CONTENT_TO_SHOW.EMPTY_STATE && (
        <div className={formatContainerClasses}>
          <div className="my-auto">
            <EmptyState cardClasses="connect-card" header="Select a folder to view documents." icon={faFolderOpen}/>
          </div>
          <div className="mt-auto"/>
        </div>
      )}
      {showBottomPortal && (<ChangeDocAccessPortal onNext={handlePreviewAccessChange}/>)}
    </React.Fragment>
  );
}

DocumentNavigation.propTypes = {
  currentFolder: PropTypes.shape({
    children: PropTypes.array.isRequired
  }),
  currentFolderLoaded: PropTypes.func,
  fetchNavigation: PropTypes.func.isRequired,
  file: PropTypes.shape({
    folder: PropTypes.bool,
    locked: PropTypes.bool,
    id: PropTypes.number.isRequired
  }),
  fileNavigationContainerSelector: PropTypes.string.isRequired,
  hasVaultGates: PropTypes.bool.isRequired,
  isEuRequest: PropTypes.bool.isRequired,
  lockedFolder: PropTypes.object,
  parentFolder: PropTypes.object,
  profile: PropTypes.object.isRequired,
  readonly: PropTypes.bool.isRequired,
  recaptchaKey: PropTypes.string.isRequired,
  rootFolder: PropTypes.shape({
    children: PropTypes.array.isRequired
  }),
  setShowInfoRequest: PropTypes.func.isRequired,
  setShowLogin: PropTypes.func.isRequired,
  showLogin: PropTypes.bool.isRequired,
  showRequestMoreInfo: PropTypes.bool.isRequired
};

const mapStateToProps = ({ navigation, file, preview, ui, vault: { hasVaultGates } }) => (
  {
    ...parseNavigationVariables(navigation),
    hasVaultGates,
    file,
    isEuRequest: ui.isEuRequest,
    profile: preview?.profile || {},
    readonly: preview.readonly,
    showLogin: ui.showLogin,
    showRequestMoreInfo: ui.showRequestMoreInfo
  }
);

const mapDispatchToProps = { fetchNavigation, setShowLogin, setShowInfoRequest };

export default connect(mapStateToProps, mapDispatchToProps)(DocumentNavigation);
