import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { faPeopleRoof, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap-5';
import { setOwners } from '../../call_list/store/actions/owners';
import { connect } from 'react-redux';
import EmptyState from '../../empty_state';
import UnlockOwners from './unlock_owners';
import TabbedOwnerList from './tabbed-owner-list';
import AccoridonOwnerList from './accordion-owner-list';

function OwnerList({ insightsPropertyId, trialUser, owners, setOwners }) {
  const [show, setShow] = useState(false);
  const [activeKey, setActiveKey] = useState();
  const sharedOwnerListProps = { activeKey, owners, setActiveKey };

  const handleOwnersTabLoaded = useCallback((event) => {
    if (event.target.dataset.jsOwnersTab) setShow(true);
  }, []);

  useEffect(() => {
    // Purposefully wait for the "owners" tab to be shown so that we don't fetch owners every single time we
    // show a property
    window.addEventListener('show.bs.tab', handleOwnersTabLoaded);

    return () => window.removeEventListener('show.bs.tab', handleOwnersTabLoaded);
  }, []);

  useEffect(() => {
    if (owners && !owners.find(owner => owner.id === parseInt(activeKey))) setActiveKey(String(owners[0]?.id));
  }, [owners, activeKey]);

  if (!show) return null;

  return (
    <div>
      <Row className="mb-4">
        <Col className="d-flex align-items-center gap-2">
          <FontAwesomeIcon className="text-secondary" icon={faPeopleRoof} />
          <h6 className="my-0 fw-bolder">Contacts</h6>
        </Col>
        <Col xs="auto">
          {/* Add Contact Button Goes Here */}
        </Col>
      </Row>
      {owners === null && (
        <UnlockOwners insightsPropertyId={insightsPropertyId} trialUser={trialUser} onUnlock={setOwners} />
      )}
      {owners && owners.length === 0 && (
        <EmptyState icon={faUser} title={'No Contacts Found'} />
      )}
      {owners && owners.length > 0 && (
        <React.Fragment>
          <div className="d-block d-lg-none">
            <AccoridonOwnerList {...sharedOwnerListProps} />
          </div>
          <div className="d-none d-lg-block">
            <TabbedOwnerList {...sharedOwnerListProps} />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

OwnerList.propTypes = {
  insightsPropertyId: PropTypes.string.isRequired,
  owners: PropTypes.array,
  setOwners: PropTypes.func.isRequired,
  status: PropTypes.number,
  trialUser: PropTypes.bool.isRequired
};

const mapStateToProps = ({ owners }) => ({ owners });
export default connect(mapStateToProps, { setOwners })(OwnerList);
