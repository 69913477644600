import {
  faApartment,
  faChairOffice,
  faFence,
  faFlag,
  faHotel,
  faHouse,
  faIndustry,
  faStore
} from '@fortawesome/pro-solid-svg-icons';

export const ACTIVITY_TYPE_CALL = 'Connect::Activities::Call';
export const ACTIVITY_TYPE_OTHER = 'Connect::Activities::Other';
export const ACTIVITY_TYPE_MEETING = 'Connect::Activities::Meeting';
export const ACTIVITY_TYPE_EMAIL = 'Connect::Activities::Email';
export const CONTACT_MODEL = 'Connect::Contact';
export const PERSON_MODEL = 'Connect::Person';
export const COMPANY_MODEL = 'Connect::Company';
export const PROPERTY_MODEL = 'Connect::Property';

export const TASK_PRIORITY_LOW = 'low';
export const TASK_PRIORITY_MEDIUM = 'medium';
export const TASK_PRIORITY_HIGH = 'high';

export const PROPERTY_TYPE_ICON_MAP = {
  1: faChairOffice,
  2: faStore,
  3: faIndustry,
  5: faFence,
  6: faApartment,
  7: faFlag,
  8: faHotel,
  10: faHouse
};

export const TENANT_EMPLOYEE_SIZE_MAP = {
  '0-25': '0-25',
  '25-100': '25-100',
  '100-250': '100-250',
  '250-1000': '250-1K',
  '1000-10000': '1K-10K',
  '10000-50000': '10K-50K',
  '50000-100000': '50K-100K',
  '>100000': 'Greater than 100K'
};

export const TENANT_SALES_REVENUE_MAP = {
  '0-1000000': 'Less than 1M',
  '1000000-10000000': '1M-10M',
  '10000000-50000000': '10M-50M',
  '50000000-100000000': '50M-100M',
  '100000000-250000000': '100M-250M',
  '250000000-500000000': '250M-500M',
  '500000000-1000000000': '500M-1B',
  '>1000000000': 'Greater than 1B'
};

export const INVESTMENT_TYPE_MAP = new Map([
  [0, 'Core'],
  [1, 'Core Plus'],
  [3, 'Opportunistic'],
  [2, 'Value Add'],
  [4, 'Distressed']
]);

export const USA_CENTER = {
  lat: 44.967243,
  lng: -103.771556
};

export const USA_BOUNDING_BOX = {
  latMin: 20,
  latMax: 45,
  lngMin: -120,
  lngMax: -70
};

export const WEST_COAST = {
  'type': 'Polygon',
  'label': 'West Coast',
  'coordinates': [
    [
      [-140.987549, 60.302464],
      [-140.993042, 69.843408],
      [-141.646729, 70.02997],
      [-142.954102, 70.318738],
      [-153.171387, 71.732662],
      [-157.983398, 71.760191],
      [-167.34375, 70.095529],
      [-168.266602, 66.82652],
      [-168.925781, 65.946472],
      [-169.453125, 65.676381],
      [-169.628906, 65.403445],
      [-169.848633, 64.623877],
      [-172.705078, 63.646259],
      [-175.253906, 60.435542],
      [-180, 50.903033],
      [-175.429688, 46.679594],
      [-174.375, 36.173357],
      [-172.265625, 5.266008],
      [-157.5, 5.266008],
      [-146.953125, 12.726084],
      [-123.925781, 24.846565],
      [-116.894531, 32.398516],
      [-114.697266, 32.62087],
      [-114.521484, 33.541395],
      [-114.082031, 34.198173],
      [-114.65332, 35.209722],
      [-120.014648, 38.993572],
      [-119.882813, 42.032974],
      [-116.982422, 42.065607],
      [-116.894531, 43.897892],
      [-116.828613, 44.229457],
      [-117.158203, 44.386692],
      [-116.828613, 44.777936],
      [-116.455078, 45.490946],
      [-116.345215, 46.103709],
      [-116.850586, 47.309034],
      [-116.982422, 48.719961],
      [-116.982422, 49.037868],
      [-123.299561, 48.99824],
      [-122.991943, 48.824949],
      [-122.991943, 48.748945],
      [-123.228149, 48.701838],
      [-123.156738, 48.447422],
      [-123.173218, 48.381794],
      [-123.338013, 48.290503],
      [-123.535767, 48.228332],
      [-123.865356, 48.301467],
      [-124.282837, 48.370848],
      [-124.595947, 48.461995],
      [-125.002441, 48.527519],
      [-125.293579, 48.523881],
      [-127.265625, 49.696062],
      [-128.232422, 49.922935],
      [-128.671875, 50.597186],
      [-130.429688, 51.316881],
      [-133.681641, 53.435719],
      [-133.494873, 54.521081],
      [-133.033447, 54.584797],
      [-130.550537, 54.718275],
      [-130.056152, 55.222757],
      [-129.935303, 55.795105],
      [-130.012207, 56.133307],
      [-130.297852, 56.170023],
      [-130.473633, 56.267761],
      [-130.737305, 56.340901],
      [-131.044922, 56.46249],
      [-131.352539, 56.644147],
      [-131.682129, 56.764768],
      [-131.835938, 57.052682],
      [-132.077637, 57.243394],
      [-132.253418, 57.456771],
      [-132.758789, 57.984808],
      [-132.978516, 58.367156],
      [-134.384766, 59.389178],
      [-135.32959, 59.899958],
      [-136.252441, 59.76746],
      [-136.889648, 59.422728],
      [-137.241211, 59.265881],
      [-137.768555, 59.723177],
      [-138.515625, 60.283408],
      [-139.042969, 60.522158],
      [-140.185547, 60.403002],
      [-140.987549, 60.302464]
    ]
  ]
};



export const WESTERN_US = {
  'type': 'Polygon',
  'label': 'Western US',
  'coordinates': [
    [
      [-124.969482, 48.502048],
      [-124.766235, 48.502048],
      [-123.997192, 48.330691],
      [-123.744507, 48.264913],
      [-123.31604, 48.275882],
      [-123.184204, 48.381794],
      [-123.244629, 48.545705],
      [-123.272095, 48.680081],
      [-122.997437, 48.777913],
      [-123.030396, 48.857487],
      [-123.321533, 49.001844],
      [-104.048767, 49.001844],
      [-104.036407, 44.996854],
      [-104.05632, 44.996854],
      [-104.052887, 41.002703],
      [-102.051659, 41.002184],
      [-102.035522, 36.998166],
      [-103.007813, 36.998166],
      [-103.001976, 36.500253],
      [-103.041458, 36.500391],
      [-103.063431, 31.999924],
      [-106.615448, 31.999924],
      [-106.631927, 31.988277],
      [-106.642914, 31.985948],
      [-106.632614, 31.972551],
      [-106.618881, 31.973716],
      [-106.620941, 31.960318],
      [-106.614075, 31.954784],
      [-106.615105, 31.950123],
      [-106.620255, 31.942549],
      [-106.625748, 31.927107],
      [-106.609955, 31.920113],
      [-106.615105, 31.915159],
      [-106.625061, 31.913702],
      [-106.632271, 31.911079],
      [-106.646004, 31.899712],
      [-106.643944, 31.893883],
      [-106.637077, 31.892425],
      [-106.630211, 31.887761],
      [-106.627808, 31.881931],
      [-106.631927, 31.875517],
      [-106.634331, 31.871143],
      [-106.635103, 31.86728],
      [-106.627979, 31.861886],
      [-106.625662, 31.859553],
      [-106.625834, 31.857731],
      [-106.614418, 31.847232],
      [-106.607208, 31.847159],
      [-106.600857, 31.844316],
      [-106.601028, 31.839649],
      [-106.602917, 31.836441],
      [-106.604741, 31.829185],
      [-106.604483, 31.828894],
      [-106.605062, 31.828073],
      [-106.602101, 31.824573],
      [-106.593647, 31.824846],
      [-106.586394, 31.821528],
      [-106.585321, 31.819231],
      [-106.582704, 31.816605],
      [-106.581502, 31.81511],
      [-106.580944, 31.813761],
      [-106.576524, 31.81015],
      [-106.576223, 31.810333],
      [-106.570816, 31.810187],
      [-106.566567, 31.81336],
      [-106.562963, 31.812594],
      [-106.562748, 31.811208],
      [-106.558542, 31.810515],
      [-106.556826, 31.810223],
      [-106.555796, 31.810041],
      [-106.550474, 31.80829],
      [-106.548629, 31.80767],
      [-106.546354, 31.807452],
      [-106.543436, 31.80333],
      [-106.537256, 31.800121],
      [-106.536741, 31.798917],
      [-106.53511, 31.79775],
      [-106.533866, 31.796291],
      [-106.532836, 31.794248],
      [-106.531849, 31.792461],
      [-106.52936, 31.79206],
      [-106.526656, 31.789725],
      [-106.527128, 31.787391],
      [-106.527772, 31.785384],
      [-106.527429, 31.783998],
      [-106.527987, 31.783816],
      [-108.201599, 31.781882],
      [-108.209839, 31.332525],
      [-111.071777, 31.327833],
      [-114.807129, 32.49123],
      [-114.808502, 32.515552],
      [-114.810562, 32.531184],
      [-114.804382, 32.565333],
      [-114.803009, 32.594263],
      [-114.789276, 32.623762],
      [-114.761124, 32.646891],
      [-114.737778, 32.688509],
      [-114.719925, 32.719421],
      [-117.238541, 32.525974],
      [-119.102783, 32.10119],
      [-122.55249, 33.906896],
      [-122.980957, 35.353216],
      [-127.001953, 41.771312],
      [-127.441406, 45.767523],
      [-124.969482, 48.502048]
    ]
  ]
};


export const EAST_COAST = {
  'type': 'Polygon',
  'label': 'East Coast',
  'coordinates': [
    [
      [-81.650391, 24.106647],
      [-80.024414, 24.726875],
      [-79.562988, 25.819672],
      [-79.82666, 28.0235],
      [-80.760498, 30.420256],
      [-79.562988, 32.147711],
      [-77.607422, 32.694866],
      [-74.179688, 33.72434],
      [-70.751953, 37.439974],
      [-67.939453, 40.513799],
      [-67.324219, 43.644026],
      [-66.950684, 44.879228],
      [-67.151184, 45.15299],
      [-67.225342, 45.168484],
      [-67.269287, 45.180101],
      [-67.310486, 45.164611],
      [-67.2995, 45.143305],
      [-67.343445, 45.13168],
      [-67.483521, 45.272954],
      [-67.420349, 45.375302],
      [-67.505493, 45.48902],
      [-67.428589, 45.498647],
      [-67.450562, 45.602509],
      [-67.785645, 45.67932],
      [-67.791138, 47.06638],
      [-67.879028, 47.100045],
      [-67.922974, 47.163575],
      [-67.966919, 47.199044],
      [-68.041077, 47.245678],
      [-68.139954, 47.297859],
      [-68.15094, 47.325792],
      [-68.307495, 47.353711],
      [-68.348694, 47.35185],
      [-68.37616, 47.345337],
      [-68.385773, 47.330912],
      [-68.384743, 47.312293],
      [-68.384743, 47.291107],
      [-68.424911, 47.28575],
      [-68.470917, 47.293202],
      [-68.494949, 47.30205],
      [-68.534088, 47.291805],
      [-68.562927, 47.289476],
      [-68.582153, 47.292271],
      [-68.601379, 47.275502],
      [-68.606358, 47.268281],
      [-68.604469, 47.263621],
      [-68.608932, 47.255466],
      [-68.630047, 47.248824],
      [-68.664379, 47.246377],
      [-68.701115, 47.24428],
      [-68.717937, 47.245911],
      [-68.74712, 47.231342],
      [-68.783169, 47.223998],
      [-68.832607, 47.210007],
      [-68.862648, 47.193912],
      [-68.89801, 47.182013],
      [-68.907795, 47.184696],
      [-68.915176, 47.193912],
      [-68.936462, 47.205109],
      [-68.976288, 47.218635],
      [-69.036026, 47.24661],
      [-69.046326, 47.263854],
      [-69.044952, 47.271309],
      [-69.048386, 47.297394],
      [-69.049072, 47.309034],
      [-69.053879, 47.314621],
      [-69.054265, 47.375744],
      [-69.053965, 47.377604],
      [-69.05036, 47.378011],
      [-69.047828, 47.380888],
      [-69.043322, 47.382079],
      [-69.041262, 47.382253],
      [-69.039116, 47.383735],
      [-69.038301, 47.386176],
      [-69.040875, 47.388384],
      [-69.043236, 47.392655],
      [-69.025726, 47.404391],
      [-69.038773, 47.421351],
      [-69.049072, 47.431571],
      [-69.062805, 47.435055],
      [-69.067955, 47.432152],
      [-69.079285, 47.42681],
      [-69.092846, 47.429249],
      [-69.100056, 47.433081],
      [-69.106922, 47.436332],
      [-69.120312, 47.442253],
      [-69.133015, 47.445504],
      [-69.141598, 47.447478],
      [-69.159279, 47.453282],
      [-69.177818, 47.457809],
      [-69.195843, 47.457925],
      [-69.209919, 47.455603],
      [-69.214211, 47.45688],
      [-69.222622, 47.460246],
      [-69.225047, 47.459361],
      [-69.22878, 47.455908],
      [-69.996643, 46.695845],
      [-70.055866, 46.415967],
      [-70.056553, 46.414665],
      [-70.061016, 46.414488],
      [-70.063248, 46.414014],
      [-70.066595, 46.413364],
      [-70.086079, 46.410582],
      [-70.090714, 46.411352],
      [-70.096893, 46.40999],
      [-70.102644, 46.404842],
      [-70.103416, 46.398923],
      [-70.112686, 46.389096],
      [-70.119553, 46.386965],
      [-70.130539, 46.38057],
      [-70.129852, 46.372991],
      [-70.132942, 46.371095],
      [-70.142899, 46.371095],
      [-70.198517, 46.353563],
      [-70.235596, 46.323223],
      [-70.30014, 46.195042],
      [-70.267181, 46.13988],
      [-70.345459, 46.073231],
      [-70.339966, 45.93205],
      [-70.339966, 45.874712],
      [-70.427856, 45.809658],
      [-70.411377, 45.740693],
      [-70.477295, 45.690833],
      [-70.609131, 45.675482],
      [-70.72998, 45.540984],
      [-70.861816, 45.471688],
      [-71.268311, 45.336702],
      [-71.542969, 45.158801],
      [-71.51001, 45.003651],
      [-71.531982, 44.9784],
      [-71.51001, 44.918139],
      [-71.551208, 44.857816],
      [-71.639099, 44.775986],
      [-71.595154, 44.633482],
      [-71.600647, 44.516093],
      [-72.062073, 44.331707],
      [-72.092285, 44.123085],
      [-72.163696, 43.949327],
      [-72.394409, 43.651975],
      [-72.493286, 43.165123],
      [-72.592163, 42.787339],
      [-72.581177, 42.729866],
      [-73.265076, 42.740961],
      [-73.281555, 42.785323],
      [-73.267822, 42.851806],
      [-73.234863, 43.285203],
      [-73.223877, 43.500752],
      [-73.256836, 43.588349],
      [-73.388672, 43.588349],
      [-73.355713, 43.715535],
      [-73.311768, 43.842451],
      [-73.399658, 44.103365],
      [-73.311768, 44.276671],
      [-73.256836, 44.449468],
      [-73.322754, 44.995883],
      [-73.959961, 45.003651],
      [-74.674072, 45.003651],
      [-74.904785, 45.034715],
      [-75.640869, 44.653024],
      [-76.234131, 44.292401],
      [-76.420898, 44.182204],
      [-76.838379, 43.723475],
      [-78.706055, 43.675818],
      [-79.277344, 43.444943],
      [-79.07959, 42.85986],
      [-79.782715, 42.512602],
      [-80.524292, 42.322001],
      [-80.529785, 39.732538],
      [-79.453125, 39.639538],
      [-78.046875, 39.470125],
      [-78.936768, 38.784063],
      [-79.991455, 38.078366],
      [-80.169983, 37.803274],
      [-80.282593, 37.622934],
      [-80.348511, 37.505368],
      [-80.744019, 37.4138],
      [-81.309814, 37.260938],
      [-81.507568, 37.256566],
      [-81.68335, 37.239075],
      [-81.903076, 37.378888],
      [-81.968994, 37.51844],
      [-82.287598, 37.352693],
      [-82.694092, 37.160317],
      [-83.045654, 36.862043],
      [-83.605957, 36.589068],
      [-81.930542, 36.594581],
      [-81.920929, 36.61663],
      [-81.643524, 36.611118],
      [-81.677856, 36.589068],
      [-81.710815, 36.425703],
      [-81.754761, 36.368222],
      [-81.947021, 36.279707],
      [-82.584229, 35.969115],
      [-83.204956, 35.755428],
      [-83.869629, 35.424868],
      [-84.309082, 35.092945],
      [-84.320068, 34.985003],
      [-85.613708, 34.982753],
      [-85.215454, 33.008663],
      [-85.12207, 32.657876],
      [-85.019073, 32.535236],
      [-85.000534, 32.48428],
      [-85.006714, 32.437931],
      [-84.979248, 32.400835],
      [-85.020447, 32.340521],
      [-85.006714, 32.201181],
      [-85.094604, 31.982453],
      [-85.133057, 31.812229],
      [-85.100098, 31.578535],
      [-85.078125, 31.231592],
      [-85.03418, 31.024694],
      [-87.561035, 31.015279],
      [-87.659912, 30.836215],
      [-87.440186, 30.666266],
      [-87.462158, 30.382353],
      [-87.626953, 29.276816],
      [-86.52832, 24.287027],
      [-82.573242, 23.624395],
      [-81.650391, 24.106647]
    ]
  ]
};

export const NORTHEAST = {
  'type': 'Polygon',
  'label': 'Northeast',
  'coordinates': [
    [
      [-80.529785, 39.732538],
      [-79.453125, 39.639538],
      [-78.046875, 39.470125],
      [-77.717285, 38.462192],
      [-75.432129, 36.791691],
      [-72.499587, 35.564239],
      [-70.751953, 37.439974],
      [-67.939453, 40.513799],
      [-67.324219, 43.644026],
      [-66.950684, 44.879228],
      [-67.151184, 45.15299],
      [-67.225342, 45.168484],
      [-67.269287, 45.180101],
      [-67.310486, 45.164611],
      [-67.2995, 45.143305],
      [-67.343445, 45.13168],
      [-67.483521, 45.272954],
      [-67.420349, 45.375302],
      [-67.505493, 45.48902],
      [-67.428589, 45.498647],
      [-67.450562, 45.602509],
      [-67.785645, 45.67932],
      [-67.791138, 47.06638],
      [-67.879028, 47.100045],
      [-67.922974, 47.163575],
      [-67.966919, 47.199044],
      [-68.041077, 47.245678],
      [-68.139954, 47.297859],
      [-68.15094, 47.325792],
      [-68.307495, 47.353711],
      [-68.348694, 47.35185],
      [-68.37616, 47.345337],
      [-68.385773, 47.330912],
      [-68.384743, 47.312293],
      [-68.384743, 47.291107],
      [-68.424911, 47.28575],
      [-68.470917, 47.293202],
      [-68.494949, 47.30205],
      [-68.534088, 47.291805],
      [-68.562927, 47.289476],
      [-68.582153, 47.292271],
      [-68.601379, 47.275502],
      [-68.606358, 47.268281],
      [-68.604469, 47.263621],
      [-68.608932, 47.255466],
      [-68.630047, 47.248824],
      [-68.664379, 47.246377],
      [-68.701115, 47.24428],
      [-68.717937, 47.245911],
      [-68.74712, 47.231342],
      [-68.783169, 47.223998],
      [-68.832607, 47.210007],
      [-68.862648, 47.193912],
      [-68.89801, 47.182013],
      [-68.907795, 47.184696],
      [-68.915176, 47.193912],
      [-68.936462, 47.205109],
      [-68.976288, 47.218635],
      [-69.036026, 47.24661],
      [-69.046326, 47.263854],
      [-69.044952, 47.271309],
      [-69.048386, 47.297394],
      [-69.049072, 47.309034],
      [-69.053879, 47.314621],
      [-69.054265, 47.375744],
      [-69.053965, 47.377604],
      [-69.05036, 47.378011],
      [-69.047828, 47.380888],
      [-69.043322, 47.382079],
      [-69.041262, 47.382253],
      [-69.039116, 47.383735],
      [-69.038301, 47.386176],
      [-69.040875, 47.388384],
      [-69.043236, 47.392655],
      [-69.025726, 47.404391],
      [-69.038773, 47.421351],
      [-69.049072, 47.431571],
      [-69.062805, 47.435055],
      [-69.067955, 47.432152],
      [-69.079285, 47.42681],
      [-69.092846, 47.429249],
      [-69.100056, 47.433081],
      [-69.106922, 47.436332],
      [-69.120312, 47.442253],
      [-69.133015, 47.445504],
      [-69.141598, 47.447478],
      [-69.159279, 47.453282],
      [-69.177818, 47.457809],
      [-69.195843, 47.457925],
      [-69.209919, 47.455603],
      [-69.214211, 47.45688],
      [-69.222622, 47.460246],
      [-69.225047, 47.459361],
      [-69.22878, 47.455908],
      [-69.996643, 46.695845],
      [-70.055866, 46.415967],
      [-70.056553, 46.414665],
      [-70.061016, 46.414488],
      [-70.063248, 46.414014],
      [-70.066595, 46.413364],
      [-70.086079, 46.410582],
      [-70.090714, 46.411352],
      [-70.096893, 46.40999],
      [-70.102644, 46.404842],
      [-70.103416, 46.398923],
      [-70.112686, 46.389096],
      [-70.119553, 46.386965],
      [-70.130539, 46.38057],
      [-70.129852, 46.372991],
      [-70.132942, 46.371095],
      [-70.142899, 46.371095],
      [-70.198517, 46.353563],
      [-70.235596, 46.323223],
      [-70.30014, 46.195042],
      [-70.267181, 46.13988],
      [-70.345459, 46.073231],
      [-70.339966, 45.93205],
      [-70.339966, 45.874712],
      [-70.427856, 45.809658],
      [-70.411377, 45.740693],
      [-70.477295, 45.690833],
      [-70.609131, 45.675482],
      [-70.72998, 45.540984],
      [-70.861816, 45.471688],
      [-71.268311, 45.336702],
      [-71.542969, 45.158801],
      [-71.507263, 45.01336],
      [-71.819, 45.01239],
      [-72.191162, 45.008506],
      [-73.004837, 45.019185],
      [-73.959961, 45.003651],
      [-74.674072, 45.003651],
      [-74.904785, 45.034715],
      [-75.640869, 44.653024],
      [-76.234131, 44.292401],
      [-76.420898, 44.182204],
      [-76.838379, 43.723475],
      [-78.706055, 43.675818],
      [-79.277344, 43.444943],
      [-79.07959, 42.85986],
      [-79.782715, 42.512602],
      [-80.524292, 42.322001],
      [-80.529785, 39.732538]
    ]
  ]
};



export const EAST_OF_MS_RIVER = {
  'type': 'Polygon',
  'label': 'Eastern US',
  'coordinates': [
    [
      [-83.100586, 41.943149],
      [-83.023682, 42.277309],
      [-82.573242, 42.516651],
      [-82.353516, 43.012681],
      [-82.089844, 43.580391],
      [-82.463379, 45.259422],
      [-83.474121, 45.828799],
      [-87.583008, 48.078079],
      [-88.461914, 48.224673],
      [-89.296875, 48.04871],
      [-91.40625, 47.010226],
      [-92.548828, 46.468133],
      [-93.120117, 45.675482],
      [-92.8125, 44.496505],
      [-91.40625, 43.834527],
      [-91.054688, 42.875964],
      [-90.454559, 42.299151],
      [-90.347443, 42.1746],
      [-90.234833, 42.128784],
      [-90.182648, 42.056431],
      [-90.160675, 42.004407],
      [-90.181274, 41.954384],
      [-90.178528, 41.898188],
      [-90.195007, 41.870583],
      [-90.227966, 41.84092],
      [-90.631714, 41.529142],
      [-90.943451, 41.526058],
      [-91.186523, 41.393294],
      [-91.109619, 41.219986],
      [-91.035461, 41.104191],
      [-91.005249, 40.984045],
      [-91.104126, 40.772222],
      [-91.428223, 40.526327],
      [-91.516113, 40.134791],
      [-91.384277, 39.732538],
      [-90.98877, 39.410733],
      [-90.65918, 39.027719],
      [-90.241699, 37.991834],
      [-89.516602, 37.439974],
      [-89.556427, 37.267496],
      [-89.432831, 37.081476],
      [-89.272156, 36.91696],
      [-89.391632, 36.79279],
      [-89.511108, 36.668419],
      [-90.241699, 35.083956],
      [-91.010742, 33.870416],
      [-91.241455, 33.26625],
      [-91.312866, 32.087229],
      [-91.724854, 31.156408],
      [-91.241455, 30.287532],
      [-90.527344, 29.840644],
      [-89.813232, 29.449165],
      [-83.540039, 23.84565],
      [-80.266113, 24.20689],
      [-79.672852, 25.562265],
      [-79.321289, 27.547242],
      [-74.069824, 32.417066],
      [-72.499587, 35.564239],
      [-70.751953, 37.439974],
      [-67.939453, 40.513799],
      [-67.324219, 43.644026],
      [-66.950684, 44.879228],
      [-67.151184, 45.15299],
      [-67.225342, 45.168484],
      [-67.269287, 45.180101],
      [-67.310486, 45.164611],
      [-67.2995, 45.143305],
      [-67.343445, 45.13168],
      [-67.483521, 45.272954],
      [-67.420349, 45.375302],
      [-67.505493, 45.48902],
      [-67.428589, 45.498647],
      [-67.450562, 45.602509],
      [-67.785645, 45.67932],
      [-67.791138, 47.06638],
      [-67.879028, 47.100045],
      [-67.922974, 47.163575],
      [-67.966919, 47.199044],
      [-68.041077, 47.245678],
      [-68.139954, 47.297859],
      [-68.15094, 47.325792],
      [-68.307495, 47.353711],
      [-68.348694, 47.35185],
      [-68.37616, 47.345337],
      [-68.385773, 47.330912],
      [-68.384743, 47.312293],
      [-68.384743, 47.291107],
      [-68.424911, 47.28575],
      [-68.470917, 47.293202],
      [-68.494949, 47.30205],
      [-68.534088, 47.291805],
      [-68.562927, 47.289476],
      [-68.582153, 47.292271],
      [-68.601379, 47.275502],
      [-68.606358, 47.268281],
      [-68.604469, 47.263621],
      [-68.608932, 47.255466],
      [-68.630047, 47.248824],
      [-68.664379, 47.246377],
      [-68.701115, 47.24428],
      [-68.717937, 47.245911],
      [-68.74712, 47.231342],
      [-68.783169, 47.223998],
      [-68.832607, 47.210007],
      [-68.862648, 47.193912],
      [-68.89801, 47.182013],
      [-68.907795, 47.184696],
      [-68.915176, 47.193912],
      [-68.936462, 47.205109],
      [-68.976288, 47.218635],
      [-69.036026, 47.24661],
      [-69.046326, 47.263854],
      [-69.044952, 47.271309],
      [-69.048386, 47.297394],
      [-69.049072, 47.309034],
      [-69.053879, 47.314621],
      [-69.054265, 47.375744],
      [-69.053965, 47.377604],
      [-69.05036, 47.378011],
      [-69.047828, 47.380888],
      [-69.043322, 47.382079],
      [-69.041262, 47.382253],
      [-69.039116, 47.383735],
      [-69.038301, 47.386176],
      [-69.040875, 47.388384],
      [-69.043236, 47.392655],
      [-69.025726, 47.404391],
      [-69.038773, 47.421351],
      [-69.049072, 47.431571],
      [-69.062805, 47.435055],
      [-69.067955, 47.432152],
      [-69.079285, 47.42681],
      [-69.092846, 47.429249],
      [-69.100056, 47.433081],
      [-69.106922, 47.436332],
      [-69.120312, 47.442253],
      [-69.133015, 47.445504],
      [-69.141598, 47.447478],
      [-69.159279, 47.453282],
      [-69.177818, 47.457809],
      [-69.195843, 47.457925],
      [-69.209919, 47.455603],
      [-69.214211, 47.45688],
      [-69.222622, 47.460246],
      [-69.225047, 47.459361],
      [-69.22878, 47.455908],
      [-69.996643, 46.695845],
      [-70.055866, 46.415967],
      [-70.056553, 46.414665],
      [-70.061016, 46.414488],
      [-70.063248, 46.414014],
      [-70.066595, 46.413364],
      [-70.086079, 46.410582],
      [-70.090714, 46.411352],
      [-70.096893, 46.40999],
      [-70.102644, 46.404842],
      [-70.103416, 46.398923],
      [-70.112686, 46.389096],
      [-70.119553, 46.386965],
      [-70.130539, 46.38057],
      [-70.129852, 46.372991],
      [-70.132942, 46.371095],
      [-70.142899, 46.371095],
      [-70.198517, 46.353563],
      [-70.235596, 46.323223],
      [-70.30014, 46.195042],
      [-70.267181, 46.13988],
      [-70.345459, 46.073231],
      [-70.339966, 45.93205],
      [-70.339966, 45.874712],
      [-70.427856, 45.809658],
      [-70.411377, 45.740693],
      [-70.477295, 45.690833],
      [-70.609131, 45.675482],
      [-70.72998, 45.540984],
      [-70.861816, 45.471688],
      [-71.268311, 45.336702],
      [-71.542969, 45.158801],
      [-71.51001, 45.003651],
      [-71.531982, 44.9784],
      [-71.51001, 44.918139],
      [-71.551208, 44.857816],
      [-71.639099, 44.775986],
      [-71.595154, 44.633482],
      [-71.600647, 44.516093],
      [-72.062073, 44.331707],
      [-72.092285, 44.123085],
      [-72.163696, 43.949327],
      [-72.394409, 43.651975],
      [-72.493286, 43.165123],
      [-72.592163, 42.787339],
      [-72.581177, 42.729866],
      [-73.265076, 42.740961],
      [-73.281555, 42.785323],
      [-73.267822, 42.851806],
      [-73.234863, 43.285203],
      [-73.223877, 43.500752],
      [-73.256836, 43.588349],
      [-73.388672, 43.588349],
      [-73.355713, 43.715535],
      [-73.311768, 43.842451],
      [-73.399658, 44.103365],
      [-73.311768, 44.276671],
      [-73.256836, 44.449468],
      [-73.322754, 44.995883],
      [-73.959961, 45.003651],
      [-74.674072, 45.003651],
      [-74.904785, 45.034715],
      [-75.640869, 44.653024],
      [-76.234131, 44.292401],
      [-76.420898, 44.182204],
      [-76.838379, 43.723475],
      [-78.706055, 43.675818],
      [-79.277344, 43.444943],
      [-79.07959, 42.85986],
      [-79.782715, 42.512602],
      [-80.524292, 42.322001],
      [-81.584473, 42.065607],
      [-83.100586, 41.943149]
    ]
  ]
};



export const MIDWEST = {
  'type': 'Polygon',
  'label': 'Midwest',
  'coordinates': [
    [
      [-104.046021, 49.005447],
      [-104.039841, 44.997825],
      [-104.06044, 44.997339],
      [-104.040527, 41.004775],
      [-102.046509, 41.00063],
      [-102.041016, 39.985538],
      [-102.041016, 36.993778],
      [-94.603271, 36.993778],
      [-94.614258, 36.491973],
      [-90.164795, 36.500805],
      [-90.152435, 36.461054],
      [-90.149002, 36.423493],
      [-90.130463, 36.394757],
      [-90.098877, 36.385913],
      [-90.082397, 36.367116],
      [-90.09613, 36.321764],
      [-90.131836, 36.280814],
      [-90.170288, 36.233197],
      [-90.233459, 36.195525],
      [-90.271912, 36.152291],
      [-90.318604, 36.100157],
      [-90.387268, 36.040216],
      [-90.392761, 35.993563],
      [-89.711609, 35.998008],
      [-89.296875, 36.633162],
      [-88.769531, 36.985003],
      [-87.626953, 37.822802],
      [-84.814453, 38.719805],
      [-84.122314, 38.625454],
      [-83.430176, 38.530979],
      [-82.924805, 38.61687],
      [-82.397461, 38.307181],
      [-80.463867, 40.245992],
      [-80.535278, 42.065607],
      [-81.518555, 42.073762],
      [-82.452393, 41.681118],
      [-83.056641, 41.934977],
      [-82.858887, 42.256984],
      [-82.468872, 42.654162],
      [-82.391968, 43.032761],
      [-82.144775, 43.580391],
      [-82.617188, 45.367584],
      [-88.286133, 48.268569],
      [-89.318848, 48.034019],
      [-90.043945, 48.173412],
      [-90.461426, 48.144098],
      [-90.889893, 48.253941],
      [-91.494141, 48.151428],
      [-91.966553, 48.414619],
      [-93.153076, 48.69096],
      [-93.603516, 48.632909],
      [-93.999023, 48.69096],
      [-94.625244, 48.821333],
      [-94.855957, 49.37522],
      [-95.163574, 49.360912],
      [-95.152588, 49.001844],
      [-99.338379, 48.980217],
      [-104.046021, 49.005447]
    ]
  ]
};


export const SOUTH = {
  'type': 'Polygon',
  'label': 'South',
  'coordinates': [
    [
      [-97.558594, 25.958045],
      [-80.947266, 24.686952],
      [-79.848633, 25.005973],
      [-79.277344, 27.644606],
      [-78.068848, 30.145127],
      [-75.19043, 35.317366],
      [-75.629883, 36.791691],
      [-75.27832, 37.926868],
      [-78.112793, 38.608286],
      [-80.595703, 40.380028],
      [-81.090088, 39.444678],
      [-82.397461, 38.771216],
      [-82.595215, 38.410827],
      [-84.605713, 39.070379],
      [-85.935059, 38.134557],
      [-87.802734, 37.961523],
      [-89.099121, 36.897194],
      [-89.538574, 36.500805],
      [-94.570313, 36.527295],
      [-94.625244, 37.011326],
      [-103.040771, 36.993778],
      [-103.035278, 36.518466],
      [-103.183594, 31.952162],
      [-106.567383, 31.914868],
      [-105.161133, 30.448674],
      [-104.414063, 29.382175],
      [-103.156128, 28.492833],
      [-102.321167, 29.659416],
      [-101.568604, 29.726222],
      [-100.678711, 28.88316],
      [-99.09668, 26.431228],
      [-97.558594, 25.958045]
    ]
  ]
};


export const SOUTHWEST = {
  'type': 'Polygon',
  'label': 'Southwest',
  'coordinates': [
    [
      [-109.091037, 31.33549],
      [-108.193359, 31.353637],
      [-108.171387, 31.774878],
      [-106.567383, 31.793555],
      [-106.578369, 31.998759],
      [-103.078423, 32.012888],
      [-103.001519, 36.954426],
      [-102.041016, 36.985003],
      [-102.041016, 41.013066],
      [-109.088745, 41.021355],
      [-111.060791, 40.988192],
      [-111.049805, 42.000325],
      [-119.926758, 42.000325],
      [-119.882813, 39.027719],
      [-114.65332, 34.921971],
      [-114.559937, 33.72434],
      [-114.719238, 33.109948],
      [-114.818115, 32.49123],
      [-111.022339, 31.311408],
      [-109.091037, 31.33549]
    ]
  ]
};


export const SOUTHEAST = {
  'type': 'Polygon',
  'label': 'Southeast',
  'coordinates': [
    [
      [-84.814453, 39.095963],
      [-85.803223, 38.289937],
      [-87.802734, 37.857507],
      [-88.549805, 37.195331],
      [-89.060669, 36.980615],
      [-89.296875, 36.527295],
      [-89.549561, 35.748741],
      [-90.131836, 35.101934],
      [-90.648193, 34.50203],
      [-91.098633, 33.83392],
      [-91.054688, 32.249974],
      [-91.620483, 31.001155],
      [-89.719849, 31.001155],
      [-89.835205, 30.566991],
      [-89.533081, 30.18787],
      [-88.846436, 30.145127],
      [-86.11084, 29.42046],
      [-83.924561, 28.825425],
      [-83.38623, 26.92207],
      [-83.979492, 23.865745],
      [-80.595703, 24.367114],
      [-79.760742, 25.482951],
      [-80.068359, 31.25977],
      [-77.783203, 33.797409],
      [-75.432129, 34.759666],
      [-75.124512, 36.694851],
      [-74.597168, 39.095963],
      [-78.244629, 38.873929],
      [-79.387207, 39.402244],
      [-80.518799, 39.732538],
      [-80.639648, 40.580585],
      [-80.837402, 39.825413],
      [-81.452637, 39.385264],
      [-82.792969, 38.754083],
      [-84.814453, 39.095963]
    ]
  ]
};



export const NEW_ENGLAND = {
  'type': 'Polygon',
  'label': 'New England',
  'coordinates': [
    [
      [-73.35022, 45.015302],
      [-73.278809, 42.744995],
      [-73.509521, 42.065607],
      [-73.491669, 42.046233],
      [-73.476563, 41.046217],
      [-72.394409, 41.203456],
      [-71.861572, 41.302571],
      [-71.323242, 41.277806],
      [-70.004883, 41.079351],
      [-69.477539, 41.869561],
      [-70.180664, 42.5207],
      [-69.411621, 43.34116],
      [-68.005371, 43.707594],
      [-67.390137, 44.21371],
      [-66.928711, 44.684277],
      [-66.879272, 44.777936],
      [-67.153931, 45.143305],
      [-67.412109, 45.232349],
      [-67.384644, 45.452424],
      [-67.582397, 45.690833],
      [-67.774658, 45.935871],
      [-67.791138, 47.06638],
      [-67.884521, 47.107523],
      [-67.922974, 47.165442],
      [-67.961426, 47.215837],
      [-68.126221, 47.294134],
      [-68.25531, 47.355571],
      [-68.384399, 47.342545],
      [-68.486023, 47.288545],
      [-68.672791, 47.249407],
      [-68.863678, 47.209307],
      [-68.892517, 47.187846],
      [-69.049072, 47.424371],
      [-69.233093, 47.459666],
      [-69.99939, 46.694667],
      [-70.051575, 46.40567],
      [-70.08728, 46.407564],
      [-70.150452, 46.365884],
      [-70.20813, 46.341239],
      [-70.210876, 46.329862],
      [-70.210876, 46.301406],
      [-70.249329, 46.261544],
      [-70.279541, 46.196943],
      [-70.230103, 46.136073],
      [-70.250702, 46.103709],
      [-70.282288, 46.098948],
      [-70.294647, 46.075136],
      [-70.307007, 46.060844],
      [-70.280914, 46.060844],
      [-70.298767, 46.019853],
      [-70.301514, 45.993145],
      [-70.285034, 45.981695],
      [-70.301514, 45.966425],
      [-70.238342, 45.95115],
      [-70.235596, 45.935871],
      [-70.260315, 45.916766],
      [-70.260315, 45.874712],
      [-70.326233, 45.847934],
      [-70.397644, 45.809658],
      [-70.411377, 45.780933],
      [-70.389404, 45.727274],
      [-70.422363, 45.704261],
      [-70.469055, 45.702343],
      [-70.53772, 45.671644],
      [-70.603638, 45.623643],
      [-70.694275, 45.56791],
      [-70.735474, 45.512121],
      [-70.677795, 45.411948],
      [-70.801392, 45.463983],
      [-70.886536, 45.356005],
      [-71.015625, 45.392664],
      [-71.128235, 45.323186],
      [-71.317749, 45.346354],
      [-71.460571, 45.253622],
      [-71.507263, 45.017244],
      [-73.35022, 45.015302]
    ]
  ]
};



export const SUNBELT = {
  'type': 'Polygon',
  'label': 'Sunbelt',
  'coordinates': [
    [
      [-114.804382, 32.502813],
      [-111.071777, 31.327833],
      [-108.207092, 31.330179],
      [-108.207092, 31.784217],
      [-106.419067, 31.770208],
      [-106.21582, 31.473182],
      [-105.891724, 31.245682],
      [-105.254517, 30.734754],
      [-104.699707, 30.088108],
      [-104.611816, 29.51611],
      [-103.095703, 28.844674],
      [-102.65625, 29.61167],
      [-101.8927, 29.730992],
      [-101.030273, 29.406105],
      [-100.371094, 28.381735],
      [-99.511414, 27.518015],
      [-99.393311, 27.019984],
      [-99.052734, 26.401711],
      [-98.173828, 26.076521],
      [-96.547852, 25.839449],
      [-93.22998, 28.420391],
      [-89.25293, 28.149503],
      [-86.77002, 28.478349],
      [-84.199219, 27.25463],
      [-83.375245, 24.427145],
      [-81.386719, 23.644524],
      [-79.848633, 24.766785],
      [-79.145508, 27.722436],
      [-74.003906, 36.5626],
      [-81.5625, 36.5626],
      [-88.022461, 36.491973],
      [-89.472656, 36.421282],
      [-89.67041, 35.924645],
      [-90.32959, 35.88905],
      [-90.153809, 36.527295],
      [-94.504395, 36.474307],
      [-94.526367, 36.985003],
      [-101.99707, 36.976227],
      [-103.018799, 36.993778],
      [-109.050293, 37.002553],
      [-114.060059, 37.07271],
      [-114.191895, 36.049099],
      [-114.675293, 35.442771],
      [-114.499512, 34.687428],
      [-114.55307, 33.437171],
      [-114.678726, 32.971228],
      [-114.716492, 32.72722],
      [-114.801636, 32.597734],
      [-114.804382, 32.502813]
    ]
  ]
};

export const REGIONS = [
  WESTERN_US,
  WEST_COAST,
  EAST_COAST,
  MIDWEST,
  NORTHEAST,
  SOUTH,
  SOUTHWEST,
  SOUTHEAST,
  EAST_OF_MS_RIVER,
  SUNBELT,
  NEW_ENGLAND
];
