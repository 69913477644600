import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UnstyledDropdownToggle from 'components/bootstrap_extensions/bs5/unstyled_dropdown_toggle';
import {
  faEdit,
  faEllipsisVertical,
  faFileExport,
  faTrash
} from '@fortawesome/pro-solid-svg-icons';
import CallListExportModal from '../call_lists/export/modal';
import { deleteWithTurboStream } from '../../helpers/prospect_api';

export default function CallListActionDropdown({
  callListId,
  exportPath,
  pathForCallList,
  templates
}) {
  const [showExportModal, setShowExportModal] = useState(false);

  const handleRemove = async () => {
    confirm('Are you sure?') && await deleteWithTurboStream(pathForCallList);
  };

  return (
    <React.Fragment>
      <Dropdown drop="start">
        <Dropdown.Toggle as={UnstyledDropdownToggle} className="text-muted">
          <FontAwesomeIcon className="clickable px-2 py-1" icon={faEllipsisVertical} tabIndex={0}/>
        </Dropdown.Toggle>
        <Dropdown.Menu className="py-0" renderOnMount>
          <Dropdown.Item className="border-top-0" onClick={() => setShowExportModal(true)}>
            <FontAwesomeIcon className="me-2" fixedWidth icon={faFileExport}/>
            Export List
          </Dropdown.Item>
          <Dropdown.Item className="border-bottom-0" data-turbo={false} href={pathForCallList}>
            <FontAwesomeIcon className="me-2" fixedWidth icon={faEdit}/>
            Edit List
          </Dropdown.Item>
          <Dropdown.Item className="border-bottom-0" onClick={handleRemove}>
            <FontAwesomeIcon className="me-2" fixedWidth icon={faTrash}/>
            Delete List
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <CallListExportModal
        callListId={callListId}
        exportPath={exportPath}
        show={showExportModal}
        templates={templates}
        onHide={() => setShowExportModal(false)}
      />
    </React.Fragment>
  );
}

CallListActionDropdown.propTypes = {
  callListId: PropTypes.number.isRequired,
  exportPath: PropTypes.string.isRequired,
  pathForCallList: PropTypes.string.isRequired,
  templates: PropTypes.array.isRequired
};

