import { isFinite } from 'lodash';
import { round } from 'lodash';
import { v4 as uuid } from 'uuid';
import { trackEvent } from '../../helpers/google_analytics_helpers';

export const UPDATE_CRITERIA = 'UPDATE_CRITERIA';
export const TOGGLE_ASSET_CLASS = 'TOGGLE_ASSET_CLASS';
export const CLEAR_CRITERIA = 'CLEAR_CRITERIA';

// Cleans up a criteria location name
function sanitizeLocationName(name) {
  if (!name) return name;

  return name.replace(', USA', '');
}

export function clearBounds() {
  return {
    type: UPDATE_CRITERIA,
    payload: {
      locationsAttributes: undefined
    }
  };
}

export function clearFilters() {
  return (dispatch) => {
    dispatch({ type: CLEAR_CRITERIA });
  };
}

export function removeLocation(id) {
  return (dispatch, getState) => {
    const { criteria } = getState();

    const locationsAttributes = [...criteria.locationsAttributes].filter(loc => (loc.id != id));

    dispatch({
      type: UPDATE_CRITERIA,
      payload: { locationsAttributes }
    });
  };
}

function getPolygon(latLngArray = []) {
  // transform input to array of [lng, lat] to send to server
  const coordinates = latLngArray.map(({ lat, lng }) => [lng, lat]);

  // Valid polygons need to start and end on the same point
  if (coordinates[0] !== coordinates[latLngArray.length - 1]) {
    coordinates.push([...coordinates[0]]);
  }

  // polygon geojson is an array of polygons, so we need to intentionally wrap in array
  return [coordinates];
}

export function addBound(latLngArray, options = {}) {
  const polygon = getPolygon(latLngArray);

  return (dispatch, getState) => {
    const { criteria } = getState();

    // Case 1: We add a new location bound. We only clear all existing map drawing polygons.
    // Case 2: We add a new map drawing. We clear all existing polygons.
    const locationsAttributes = (criteria.locationsAttributes && options.name) ?
      criteria.locationsAttributes.filter(attributes => attributes.name)
      : [];

    // exclude locations that have already been added, or are just the view of the map
    if (
      locationsAttributes.some(loc => options.name && sanitizeLocationName(options.name) === loc.name)
    ) return;

    trackEvent('connect_search_bounds_added', { name: sanitizeLocationName(options.name) });
    dispatch({
      type: UPDATE_CRITERIA,
      payload: {
        locationsAttributes: [...locationsAttributes, {
          name: sanitizeLocationName(options.name),
          polygonSearch: true,
          id: uuid(),
          polygonGeojson: {
            type: 'Polygon',
            coordinates: polygon
          }
        }]
      }
    });
  };
}

export function addGeoJson(polygonGeojson, options = {}) {
  if (!polygonGeojson) return;

  return (dispatch, getState) => {
    const { criteria } = getState();

    const locationsAttributes = [...criteria.locationsAttributes?.filter(attributes => attributes.name) || []];

    trackEvent('connect_search_location_selected', { name: sanitizeLocationName(options.name) });
    dispatch({
      type: UPDATE_CRITERIA,
      payload: {
        mapBounds: undefined,
        locationsAttributes: [...locationsAttributes, {
          name: sanitizeLocationName(options.name),
          polygonSearch: true,
          id: uuid(),
          polygonGeojson
        }]
      }
    });
  };
}

// bounds is array of [{ lat:, lng:}]
export function replaceMapBounds(latLngArray) {
  return (dispatch) => {
    const latitudes = latLngArray.map(({ lat }) => lat);
    const longitudes = latLngArray.map(({ lng }) => lng);
    const north = Math.max(...latitudes);
    const south = Math.min(...latitudes);
    const west = Math.min(...longitudes);
    const east = Math.max(...longitudes);
    dispatch({
      type: UPDATE_CRITERIA,
      payload: { mapBounds: { north, east, south, west } }
    });
  };
}

export function toggleAssetClassId(id, toggle) {
  return (dispatch) => {
    dispatch({ type: TOGGLE_ASSET_CLASS, payload: { id, toggle } });
  };
}

export function setFilterAttribute(key, val, options) {
  return (dispatch, getState) => {
    if (options && options.int) {
      val = parseInt(val.replace(/\D/g, ''));
      if (!isFinite(val)) val = undefined;
    }
  
    if (options && options.percent && val) {
      val = round(parseFloat(val.replace(/[^\d.-]+/g, '')), 2);
      if (val > 100 || val < 0) return { type: 'noop' };
    }

    if (options && options.array && val) {
      const { criteria } = getState();
      const existingArr = criteria[key] || [];
      if (existingArr.includes(val)) {
        val = existingArr.filter(currentVal => currentVal !== val);
      } else {
        val = [...existingArr, val];
      }
    }

    dispatch({
      type: UPDATE_CRITERIA,
      payload: {
        [key]: val
      }
    });
  };
}
