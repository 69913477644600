import React from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSign } from '@fortawesome/pro-solid-svg-icons';

import { USD } from 'helpers/formatters/number_formats';
import { formatDate } from 'helpers/formatters/date_time_formats';
import ResultCardHighlights from './result_card_highlights';
import { Col } from 'react-bootstrap-5';

export default function OffMarketCompSummary({ property }) {
  const highlightText = property.highlights
    .map(({ label, value }) => compact([value, label]).join(' '));
  const hasListing = !!property?.listings?.forSale && (property.listings.salePrice || 0) > 0;
  let price, bannerText, bannerColor;
  if (hasListing) {
    price = USD.format(property.listings.salePrice, { round: true });
    bannerText = 'On Market';
    bannerColor = 'text-secondary';
  } else if (property.lastSalePrice) {
    price = USD.format(property.lastSalePrice, { round: true });
    bannerText = `Sold: ${formatDate(property.lastSaleDate)}`;
    bannerColor = 'text-warning';
  }


  return (
    <div className="d-flex flex-column gap-3 gap-md-2 h-100">
      {price && (
        <div>
          <div>
            <Col className={`text-xs ${bannerColor}`} xs="auto">
              <FontAwesomeIcon className="me-1" icon={faSign} />
              {bannerText}
            </Col>
          </div>
          <div className="d-flex flex-column gap-1 clickable-text">
            <div className="fw-bolder">{price}</div>
            <div className="small result-address lh-sm">
              <div>{property.address}</div>
              <div>{property.city}, {property.state} {property.zip}</div>
            </div>
          </div>
        </div>
      )}
      {!price && (
        <div className="result-address clickable-text lh-sm">
          <div className="fw-bolder">{property.address}</div>
          <small>{property.city}, {property.state} {property.zip}</small>
        </div>
      )}
      <div className="small text-muted lh-sm">
        <ResultCardHighlights highlights={highlightText} />
      </div>
    </div>
  );
}

OffMarketCompSummary.propTypes = {
  property: PropTypes.shape({
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    lastSaleDate: PropTypes.string.isRequired,
    lastSalePrice: PropTypes.number.isRequired,
    listings: PropTypes.shape({
      forSale: PropTypes.bool,
      salePrice: PropTypes.number,
    }),
  }).isRequired,
};
