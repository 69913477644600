import { Turbo } from '@hotwired/turbo-rails';
import { deleteRequest, get, patch, post, put } from 'helpers/api';
import { COMPANY_MODEL, CONTACT_MODEL, PERSON_MODEL, PROPERTY_MODEL } from '../constants';

export const offMarketPropertyUrl = (propertyId, fromConsole = false) => {
  if (fromConsole) {
    return `/prospect/off_market_properties/${propertyId}?from_console=true`;
  } else {
    return `/prospect/off_market_properties/${propertyId}`;
  }
};
export const exclusiveListingUrl = listingId => `/prospect/listings/${listingId}`;

export function fetchProfile() {
  return get('/prospect/profile');
}

export function updateProfile(profile, source) {
  return put('/prospect/profile', { profile, source });
}

export function subscriptionStatus() {
  return get('/prospect/profile/subscription_status').then(({ status }) => status);
}

export function listingSearch(criteria, signal) {
  return post('/prospect/search/search_all', { set: criteria }, { signal });
}

export function detailedListingSearch(criteria, page = 0, signal) {
  return post('/prospect/search/search', { set: criteria, page }, { signal });
}

export function findOffMarketProperty(propertyId) {
  return get(`/prospect/off_market_properties/${propertyId}`);
}

export function findOffMarketPropertyDetails(propertyId) {
  return get(`/prospect/off_market_properties/${propertyId}/details`);
}

export function findListing(listingId) {
  return get('/prospect/search/info', { params: { listingId } });
}

export function fetchOwners(insightsPropertyId, useCredit = false) {
  let url = `/prospect/off_market_properties/${insightsPropertyId}/ownerships`;
  if (useCredit) url = url + '?use_credit=true';
  return get(url);
}

export function createActivity(activity, callListId) {
  return post('/prospect/activities', { activity, callListId });
}

export function deleteActivity(activityId) {
  return deleteRequest(`/prospect/activities/${activityId}`);
}

export function updateActivity(activity) {
  return put(`/prospect/activities/${activity.id}`, { activity });
}

export function updateOwnership(insightsPropertyId, ownershipId, params) {
  return put(`/prospect/off_market_properties/${insightsPropertyId}/ownerships/${ownershipId}`, { ownership: params });
}

export function createOwnershipRequest(insightsPropertyId, contactId) {
  return post(`/prospect/off_market_properties/${insightsPropertyId}/ownerships`, { contactId });
}

export function deleteOwnershipRequest(insightsPropertyId, ownershipId) {
  return deleteRequest(`/prospect/off_market_properties/${insightsPropertyId}/ownerships/${ownershipId}`);
}

export function fetchContact(contactId) {
  return get(`/prospect/connect_contacts/${contactId}`);
}

export function fetchContactOwnedProperties(contactId) {
  return get(`/prospect/connect_contacts/${contactId}/owned_properties`);
}

export function updateContactRequest(contactId, params) {
  if (params.company && params.company.id) {
    params.companyId = params.company.id;
  }
  return put(`/prospect/connect_contacts/${contactId}`, { contact: params });
}

export function searchContacts(query, page, sort, tags = [], interacted = false) {
  const params = { q: query, page };
  if (interacted) params.interacted = true;
  if (sort) params.s = sort;
  if (tags && tags.length > 0) params.tags = tags;
  return get(`/prospect/connect_contacts`, { params });
}

export function createContact(contact) {
  if (contact.company && contact.company.id) {
    contact.companyId = contact.company.id;
  }
  return post(`/prospect/connect_contacts`, { contact });
}

export function deleteContact(contactId) {
  return deleteRequest(`/prospect/connect_contacts/${contactId}`);
}

export function deleteContacts(contactIds) {
  return deleteRequest(`/prospect/connect_contacts/destroy_multiple`, { params: { ids: contactIds } });
}

export function importContacts(flatfileSheetId) {
  return post(`/prospect/connect_contacts/import`, { flatfileSheetId });
}

export function fetchNotes(recordId, recordType) {
  return get(`/prospect/notes`, { params: { notableId: recordId, notableType: recordType } });
}

export function createNote(recordId, recordType, body, insightsPropertyId) {
  return post(`/prospect/notes`, { insightsPropertyId, note: { notableId: recordId, notableType: recordType, body } });
}

export function deleteNote(noteId) {
  return deleteRequest(`/prospect/notes/${noteId}`);
}

export function updateNote(noteId, body) {
  return put(`/prospect/notes/${noteId}`, { note: { body } });
}

export function evaluateTemplate(templateId, contactId, propertyId) {
  return get(`/prospect/liquid_templates/${templateId}/evaluate`, { params: { contactId, propertyId } });
}

export function myPropertiesByAddress(address, page = 1) {
  return get(`/prospect/properties?q[address_cont]=${address}&page=${page}`);
}

/**
 * Save a criteria to the backend.
 * @param {object} criteria - The criteria object from the search redux store
 * @param {string} name - The name to save the criteria as
 * @param {string} type - The type of criteria to save.
 *                        Either 'Connect::OffMarketSavedSearch' OR 'Connect::BuildoutSavedSearch'
 * @returns {Promise} The network request promise
 */
export function saveSearch(criteria, name, type) {
  return post(
    '/prospect/search/save_search',
    {
      set: {
        ...criteria,
        locationsAttributes: locationAttributesFromCriteria(criteria),
        name: name ? name : undefined,
        type
      }
    }
  );
}

/**
 * Performs both the detailed search and the search to fetch all results.
 * This is faster than performing both individually because the criteriaToOffMarketSearchParams method involves
 * an network request to Clarity. This method only calls criteriaToOffMarketSearchParams once and then reuses
 * the resulting params in the subsequent calls to Buildout
 * @param {object} criteria - The criteria object from the search redux store
 * @param {number} detailedLimit - The limit for the detailed off market search results
 * @param {number} limit - The limit for the off market search results
 * @param {boolean} includeGeometry - Ask insights to include geometry in the property details
 * @returns {array} Array of network request promises [detailedResults, results]
 */
export async function bothOffMarketPropertySearchesFromCriteria(
  criteria, detailedLimit, limit, includeGeometry = false, signal
) {
  const params = await criteriaToOffMarketSearchParams(criteria);
  params.includeGeometry = includeGeometry;

  return [
    detailedOffMarketPropertySearch(params, detailedLimit, signal),
    offMarketPropertySearch(params, limit, signal)
  ];
}

export async function offMarketPropertySearchFromCriteria(
  criteria,
  limit,
  includeGeometry = false,
  signal
) {
  const params = await criteriaToOffMarketSearchParams(criteria);
  params.includeGeometry = includeGeometry;

  return offMarketPropertySearch(params, limit, signal);
}

export async function detailedOffMarketPropertySearchFromCriteria(criteria, limit, signal) {
  const params = await criteriaToOffMarketSearchParams(criteria);

  return detailedOffMarketPropertySearch(params, limit, 0, signal);
}

export function detailedOffMarketPropertySearch(params, limit, offset = 0, signal) {
  return post('/prospect/off_market_properties/search', { insightsProperty: { ...params, limit, offset } }, { signal });
}

export function offMarketPropertySearch(params, limit, signal) {
  return post('/prospect/off_market_properties/search_all', { insightsProperty: { ...params, limit } }, { signal });
}

async function criteriaToOffMarketSearchParams(criteria) {
  const params = {
    ...criteria,
    mapBounds: criteria.mapBounds || []
  };

  return params;
}

export function propertyIdOffMarketPropertySearch(address, city, state) {
  return post('/prospect/off_market_properties/search_property_id_by_address', {
    address: address,
    city: city,
    state: state,
  });
}

export function propertiesByAddress(address) {
  return post('/prospect/off_market_properties/search_properties_by_address', {
    address: address
  });
}

export function offMarketAssociatedPropertiesCount(insightsPropertyId) {
  return get(`/prospect/off_market_properties/${insightsPropertyId}/associated_properties_count`);
}

/**
 * When saving a criteria to the backend, save the mapBounds as the location if no other locations are present.
 * We do this by transforming the map bounds into a valid location polygon.
 */
function locationAttributesFromCriteria(criteria) {
  if (criteria.locationsAttributes && criteria.locationsAttributes.length > 0) return criteria.locationsAttributes;

  if (criteria.mapBounds) return [{
    name: 'Map View',
    polygonSearch: true,
    polygonGeojson: {
      type: 'Polygon',
      coordinates: [[
        [criteria.mapBounds.east, criteria.mapBounds.north],
        [criteria.mapBounds.west, criteria.mapBounds.north],
        [criteria.mapBounds.west, criteria.mapBounds.south],
        [criteria.mapBounds.east, criteria.mapBounds.south],
        [criteria.mapBounds.east, criteria.mapBounds.north],
      ]]
    }
  }];
}

export function fetchValuationAdjustments(params) {
  return get('/prospect/valuation_adjustments', params);
}

export function createValuationAdjustment(body) {
  return post('/prospect/valuation_adjustments', { connect_valuation_adjustment: body });
}

export function updateValuationAdjustment(body) {
  return patch(`/prospect/valuation_adjustments/${body.id}`, { connect_valuation_adjustment: body });
}

export function deleteValuationAdjustment(id) {
  return deleteRequest(`/prospect/valuation_adjustments/${id}`);
}

export function deleteCallListItem(id) {
  return deleteRequest(`/prospect/call_list_items/${id}`);
}

export function fetchCallLists(params) {
  return get('/prospect/connect_call_lists', { params });
}

export function addPropertiesToCallList(callListId, insightPropertyIds) {
  return post(`/prospect/connect_call_lists/${callListId}/add_properties_to_call_list`, { insightPropertyIds });
}

export function addContactsToCallList(callListId, contactIds) {
  return post(`/prospect/connect_call_lists/${callListId}/add_contacts_to_call_list`, { contactIds });
}

export function createCallList(name, description) {
  return post('/prospect/connect_call_lists', { connectCallList: { name, description } });
}

export function deleteWithTurboStream(path) {
  return fetch(path, {
    method: 'DELETE',
    headers: {
      'Accept': 'text/vnd.turbo-stream.html',
      'Content-Type': 'application/json'
    }
  })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html));
}

export function fetchCallListExportCreditCounts(callListId) {
  return get(`/prospect/connect_call_lists/${callListId}/export_credit_counts`);
}

export function addItemToCallList(callListId, propertyId, callListableType, callListableId) {
  return fetch(`/prospect/connect_call_lists/${callListId}/add_call_list_item`, {
    method: 'POST',
    body: JSON.stringify({
      insights_property_id: propertyId,
      call_listable_type: callListableType,
      call_listable_id: callListableId
    }),
    headers: {
      'Accept': 'text/vnd.turbo-stream.html',
      'Content-Type': 'application/json'
    }
  })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html));
}

export function fetchRelatedProperties(propertyId) {
  // Note: Using the 36 months and this call is temporary. We should remove it when we redo the call console
  return get(`/prospect/off_market_properties/${propertyId}/related_properties?comps_search[time_period]=36`);
}

export function updateConnectProperty(propertyId, params) {
  return put(`/prospect/properties/${propertyId}`, { connectProperty: params });
}

export function fetchTags(searchString, limit) {
  return get(
    '/prospect/tags',
    { params: { 'q[name_cont]': searchString, limit } }
  );
}

export function createTag(name) {
  return post('/prospect/tags', { tag: { name } });
}

export function updateTag(id, name) {
  return put(`/prospect/tags/${id}`, { tag: { name } });
}

export function addTagToRecord(recordType, recordId, insightsPropertyId, name) {
  return post('/prospect/tags/add_to_record', { recordType, recordId, insightsPropertyId, name });
}

export function removeTagFromRecord(recordType, recordId, insightsPropertyId, name) {
  return deleteRequest(
    '/prospect/tags/remove_from_record',
    { params: { recordType, recordId, insightsPropertyId, name } }
  );
}

export function getTagsForRecord(recordType, recordId, insightsPropertyId) {
  return get('/prospect/tags/fetch_for_record', { params: { recordType, recordId, insightsPropertyId } });
}

export function deleteTag(tagId) {
  return deleteRequest(`/prospect/tags/${tagId}`);
}

export function linkFromRecord(recordType, recordId) {
  if (recordType === PROPERTY_MODEL) return `/prospect/search?property_id=${recordId}`;
  if (recordType === CONTACT_MODEL || recordType === PERSON_MODEL || recordType === COMPANY_MODEL) {
    return `/prospect/connect_contacts/${recordId}`;
  }
}

export function createDataExportTemplate(params) {
  return post('/prospect/data_export_templates', params);
}

export function updateDataExportTemplate(templateId, params) {
  return put(`/prospect/data_export_templates/${templateId}`, params);
}

export function deleteDataExportTemplate(templateId) {
  return deleteRequest(`/prospect/data_export_templates/${templateId}`);
}

export const dataExportDownloadUrl = id => `/prospect/data_exports/${id}/download`;
